import React, { Component } from "react";

class Navbar extends Component {
  state = {};
  handleArrowShowHide() {
    switch (this.props.contentView) {
      case true:
        return <React.Fragment>▲</React.Fragment>;

      default:
        return <React.Fragment>▼</React.Fragment>;
    }
  }

  styleNavbig = {};
  styleNavsmall = {
    padding: "4px 4px 4px 4px",
  };

  handleSelShowHide() {
    switch (this.props.contentView) {
      case true:
        switch (this.props.typeScreen.size) {
          case "big":
            return (
              <span style={{ float: "right", fontSize: 18 }}>
                <button
                  className="btn btn-primary"
                  onClick={() => this.props.selectAll()}
                  style={{ marginLeft: 10 }}
                >
                  Select all
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => this.props.deselectAll()}
                  style={{ marginLeft: 10, marginRight: 0 }}
                >
                  Deselect all
                </button>
                <button
                  className="btn btn-success "
                  style={{ marginLeft: 10, marginRight: 0 }}
                  disabled
                >
                  Download{" "}
                  {this.props.allpic.filter((c) => c.slctd === true).length}{" "}
                  files
                </button>
              </span>
            );

          default:
            return (
              <button
                className="btn btn-success"
                style={{ marginLeft: 5, fontSize: 14, padding: 5 }}
                disabled
              >
                Download{" "}
                {this.props.allpic.filter((c) => c.slctd === true).length} files
              </button>
            );
        }

      default:
        break;
    }
  }

  handleSizeScreen1() {
    switch (this.props.typeScreen.size) {
      case "big":
        return (
          <span>
            <span>
              <img
                src="images/Clipart/calendar.png"
                style={{ marginLeft: "15px", height: "18px", marginBottom: 5 }}
              />
              <span class="font-italic" style={{ marginLeft: "5px" }}>
                {this.props.DateTaken}
              </span>
            </span>
            <span>
              <img
                src="images/Clipart/buste.png"
                style={{ marginLeft: "15px", height: "18px", marginBottom: 6 }}
              />

              <span class="font-weight-normal" style={{ marginLeft: "5px" }}>
                {this.props.numPeople}
              </span>
            </span>
            <span>
              <img
                src="images/Clipart/location.png"
                style={{
                  marginLeft: "15px",
                  height: "18px",
                  marginBottom: 4,
                }}
              />
              <span class="font-weight-normal" style={{ marginLeft: "5px" }}>
                {this.props.location}
              </span>
            </span>
          </span>
        );
      default:
        break;
    }
  }
  render() {
    return (
      <nav
        className="navbar navbar-dark bg-dark navbar-gall"
        style={{
          margin: "0",
          padding: "0",
          position: "sticky",
          top: "56px",
          zIndex: "1",
        }}
      >
        <div className="container-fluid" style={{ padding: "4px 4px 4px 4px" }}>
          <a
            className="navbar-brand"
            href="#"
            onClick={(e) => this.props.ShowHidePic(e)}
          >
            {this.handleArrowShowHide()} {this.props.Name}
            {this.handleSizeScreen1()}
          </a>
          {this.handleSelShowHide()}
        </div>
      </nav>
    );
  }
}
export default Navbar;
