import React, { Component } from "react";

import IntroMyPictures from "./components/intro";
import Menu from "./components/menu";
import { Route, Switch } from "react-router-dom";
import PictureGall from "./components/picturegallery";
import AboutMe from "./components/aboutme";
import LandingPage from "./components/landing-page";
import BAcarousel from "./components/BAcarousel";

// import "./App.css";

const allpic1 = [
  {
    id: 1,
    source: "images/GallShowcase/GallShowcase01.webp",
    sourcesmall: "images/GallShowcase/GallShowcase01_1.webp",
    width: 3,
    height: 2,
    hvr: false,
    slctd: false,
  },
  {
    id: 2,
    source: "images/GallShowcase/GallShowcase02.webp",
    sourcesmall: "images/GallShowcase/GallShowcase02_1.webp",
    width: 3,
    height: 4,
    hvr: false,
    slctd: false,
  },
  {
    id: 3,
    source: "images/GallShowcase/GallShowcase03.webp",
    sourcesmall: "images/GallShowcase/GallShowcase03_1.webp",
    width: 1,
    height: 1,
    hvr: false,
    slctd: false,
  },
  {
    id: 4,
    source: "images/GallShowcase/GallShowcase04.webp",
    sourcesmall: "images/GallShowcase/GallShowcase04_1.webp",
    width: 4,
    height: 3,
    hvr: false,
    slctd: false,
  },
  {
    id: 5,
    source: "images/GallShowcase/GallShowcase05.webp",
    sourcesmall: "images/GallShowcase/GallShowcase05_1.webp",
    width: 2,
    height: 1,
    hvr: false,
    slctd: false,
  },
  {
    id: 6,
    source: "images/GallShowcase/GallShowcase06.webp",
    sourcesmall: "images/GallShowcase/GallShowcase06_1.webp",
    width: 3,
    height: 2,
    hvr: false,
    slctd: false,
  },
  {
    id: 7,
    source: "images/GallShowcase/GallShowcase07.webp",
    sourcesmall: "images/GallShowcase/GallShowcase07_1.webp",
    width: 16,
    height: 10,
    hvr: false,
    slctd: false,
  },
  {
    id: 8,
    source: "images/GallShowcase/GallShowcase08.webp",
    sourcesmall: "images/GallShowcase/GallShowcase08_1.webp",
    width: 3,
    height: 4,
    hvr: false,
    slctd: false,
  },
  {
    id: 9,
    source: "images/GallShowcase/GallShowcase09.webp",
    sourcesmall: "images/GallShowcase/GallShowcase09_1.webp",
    width: 1,
    height: 1,
    hvr: false,
    slctd: false,
  },
  {
    id: 10,
    source: "images/GallShowcase/GallShowcase10.webp",
    sourcesmall: "images/GallShowcase/GallShowcase10_1.webp",
    width: 3,
    height: 2,
    hvr: false,
    slctd: false,
  },
  {
    id: 11,
    source: "images/GallShowcase/GallShowcase11.webp",
    sourcesmall: "images/GallShowcase/GallShowcase11_1.webp",
    width: 2,
    height: 1,
    hvr: false,
    slctd: false,
  },
  {
    id: 12,
    source: "images/GallShowcase/GallShowcase12.webp",
    sourcesmall: "images/GallShowcase/GallShowcase12_1.webp",
    width: 1,
    height: 2,
    hvr: false,
    slctd: false,
  },
  {
    id: 13,
    source: "images/GallShowcase/GallShowcase13.webp",
    sourcesmall: "images/GallShowcase/GallShowcase13_1.webp",
    width: 1,
    height: 2,
    hvr: false,
    slctd: false,
  },
  {
    id: 14,
    source: "images/GallShowcase/GallShowcase14.webp",
    sourcesmall: "images/GallShowcase/GallShowcase14_1.webp",
    width: 3,
    height: 2,
    hvr: false,
    slctd: false,
  },
  {
    id: 15,
    source: "images/GallShowcase/GallShowcase15.webp",
    sourcesmall: "images/GallShowcase/GallShowcase15_1.webp",
    width: 4,
    height: 3,
    hvr: false,
    slctd: false,
  },
  {
    id: 16,
    source: "images/GallShowcase/GallShowcase16.webp",
    sourcesmall: "images/GallShowcase/GallShowcase16_1.webp",
    width: 2,
    height: 1,
    hvr: false,
    slctd: false,
  },
  {
    id: 17,
    source: "images/GallShowcase/GallShowcase17.webp",
    sourcesmall: "images/GallShowcase/GallShowcase17_1.webp",
    width: 1,
    height: 1,
    hvr: false,
    slctd: false,
  },
  {
    id: 18,
    source: "images/GallShowcase/GallShowcase18.webp",
    sourcesmall: "images/GallShowcase/GallShowcase18_1.webp",
    width: 16,
    height: 9,
    hvr: false,
    slctd: false,
  },
  {
    id: 19,
    source: "images/GallShowcase/GallShowcase19.webp",
    sourcesmall: "images/GallShowcase/GallShowcase19_1.webp",
    width: 2,
    height: 3,
    hvr: false,
    slctd: false,
  },
  {
    id: 20,
    source: "images/GallShowcase/GallShowcase20.webp",
    sourcesmall: "images/GallShowcase/GallShowcase20_1.webp",
    width: 4,
    height: 3,
    hvr: false,
    slctd: false,
  },
];

const allpic2 = [
  {
    id: 1,
    source: "images/GallShowcase/GallShowcase21.webp",
    sourcesmall: "images/GallShowcase/GallShowcase21_1.webp",
    width: 1,
    height: 1,
    hvr: false,
    slctd: false,
  },
  {
    id: 2,
    source: "images/GallShowcase/GallShowcase22.webp",
    sourcesmall: "images/GallShowcase/GallShowcase22_1.webp",
    width: 1,
    height: 1,
    hvr: false,
    slctd: false,
  },
  {
    id: 3,
    source: "images/GallShowcase/GallShowcase23.webp",
    sourcesmall: "images/GallShowcase/GallShowcase23_1.webp",
    width: 3,
    height: 2,
    hvr: false,
    slctd: false,
  },
  {
    id: 4,
    source: "images/GallShowcase/GallShowcase24.webp",
    sourcesmall: "images/GallShowcase/GallShowcase24_1.webp",
    width: 10,
    height: 16,
    hvr: false,
    slctd: false,
  },
  {
    id: 5,
    source: "images/GallShowcase/GallShowcase25.webp",
    sourcesmall: "images/GallShowcase/GallShowcase25_1.webp",
    width: 4,
    height: 3,
    hvr: false,
    slctd: false,
  },
  {
    id: 6,
    source: "images/GallShowcase/GallShowcase26.webp",
    sourcesmall: "images/GallShowcase/GallShowcase26_1.webp",
    width: 1,
    height: 1,
    hvr: false,
    slctd: false,
  },
  {
    id: 7,
    source: "images/GallShowcase/GallShowcase27.webp",
    sourcesmall: "images/GallShowcase/GallShowcase27_1.webp",
    width: 2,
    height: 1,
    hvr: false,
    slctd: false,
  },
  {
    id: 8,
    source: "images/GallShowcase/GallShowcase28.webp",
    sourcesmall: "images/GallShowcase/GallShowcase28_1.webp",
    width: 16,
    height: 10,
    hvr: false,
    slctd: false,
  },
  {
    id: 9,
    source: "images/GallShowcase/GallShowcase29.webp",
    sourcesmall: "images/GallShowcase/GallShowcase29_1.webp",
    width: 1,
    height: 1,
    hvr: false,
    slctd: false,
  },
  {
    id: 10,
    source: "images/GallShowcase/GallShowcase30.webp",
    sourcesmall: "images/GallShowcase/GallShowcase30_1.webp",
    width: 3,
    height: 1,
    hvr: false,
    slctd: false,
  },
  {
    id: 11,
    source: "images/GallShowcase/GallShowcase31.webp",
    sourcesmall: "images/GallShowcase/GallShowcase31_1.webp",
    width: 4,
    height: 3,
    hvr: false,
    slctd: false,
  },
  {
    id: 12,
    source: "images/GallShowcase/GallShowcase32.webp",
    sourcesmall: "images/GallShowcase/GallShowcase32_1.webp",
    width: 3,
    height: 2,
    hvr: false,
    slctd: false,
  },
  {
    id: 13,
    source: "images/GallShowcase/GallShowcase33.webp",
    sourcesmall: "images/GallShowcase/GallShowcase33_1.webp",
    width: 16,
    height: 9,
    hvr: false,
    slctd: false,
  },
  {
    id: 14,
    source: "images/GallShowcase/GallShowcase34.webp",
    sourcesmall: "images/GallShowcase/GallShowcase34_1.webp",
    width: 3,
    height: 2,
    hvr: false,
    slctd: false,
  },
  {
    id: 15,
    source: "images/GallShowcase/GallShowcase35.webp",
    sourcesmall: "images/GallShowcase/GallShowcase35_1.webp",
    width: 1,
    height: 1,
    hvr: false,
    slctd: false,
  },
  {
    id: 16,
    source: "images/GallShowcase/GallShowcase36.webp",
    sourcesmall: "images/GallShowcase/GallShowcase36_1.webp",
    width: 2,
    height: 1,
    hvr: false,
    slctd: false,
  },
  {
    id: 17,
    source: "images/GallShowcase/GallShowcase37.webp",
    sourcesmall: "images/GallShowcase/GallShowcase37_1.webp",
    width: 4,
    height: 3,
    hvr: false,
    slctd: false,
  },
  {
    id: 18,
    source: "images/GallShowcase/GallShowcase38.webp",
    sourcesmall: "images/GallShowcase/GallShowcase38_1.webp",
    width: 1,
    height: 1,
    hvr: false,
    slctd: false,
  },
  {
    id: 19,
    source: "images/GallShowcase/GallShowcase39.webp",
    sourcesmall: "images/GallShowcase/GallShowcase39_1.webp",
    width: 16,
    height: 9,
    hvr: false,
    slctd: false,
  },
  {
    id: 20,
    source: "images/GallShowcase/GallShowcase40.webp",
    sourcesmall: "images/GallShowcase/GallShowcase40_1.webp",
    width: 1,
    height: 1,
    hvr: false,
    slctd: false,
  },
];

class App extends Component {
  render() {
    return (
      <div className="background">
        <Menu />
        <div className="testroute">
          <Switch>
            <Route
              path="/gallery"
              render={() => (
                <React.Fragment>
                  <IntroMyPictures />
                  <PictureGall
                    Name="Example 1"
                    DateTaken="05.09.19-20.01.20"
                    numPeople="2"
                    location="Philippines"
                    allpic={allpic1}
                  />
                  <PictureGall
                    Name="Example 2"
                    DateTaken="21.01.20-20.03.20"
                    numPeople="2"
                    location="Philippines"
                    allpic={allpic2}
                  />
                </React.Fragment>
              )}
            />
            {/* <Route path="/AboutMe" component={AboutMe} /> */}
            <Route path="/beforeafter" component={BAcarousel} />
            <Route path="/" component={AboutMe} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default App;
