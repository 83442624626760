import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import PictureGall from "./components/picturegallery";
import Menu from "./components/menu";
import Intro from "./components/intro";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

const allpic1 = [
  {
    id: 1,
    source: "images/GallShowcase/GallShowcase01.webp",
    width: 3,
    height: 2,
    hvr: false,
    slctd: false,
  },
  {
    id: 2,
    source: "images/GallShowcase/GallShowcase02.webp",
    width: 3,
    height: 4,
    hvr: false,
    slctd: false,
  },
  {
    id: 3,
    source: "images/GallShowcase/GallShowcase03.webp",
    width: 1,
    height: 1,
    hvr: false,
    slctd: false,
  },
  {
    id: 4,
    source: "images/GallShowcase/GallShowcase04.webp",
    width: 4,
    height: 3,
    hvr: false,
    slctd: false,
  },
  {
    id: 5,
    source: "images/GallShowcase/GallShowcase05.webp",
    width: 2,
    height: 1,
    hvr: false,
    slctd: false,
  },
  {
    id: 6,
    source: "images/GallShowcase/GallShowcase06.webp",
    width: 3,
    height: 2,
    hvr: false,
    slctd: false,
  },
  {
    id: 7,
    source: "images/GallShowcase/GallShowcase07.webp",
    width: 16,
    height: 10,
    hvr: false,
    slctd: false,
  },
  {
    id: 8,
    source: "images/GallShowcase/GallShowcase08.webp",
    width: 3,
    height: 4,
    hvr: false,
    slctd: false,
  },
  {
    id: 9,
    source: "images/GallShowcase/GallShowcase09.webp",
    width: 1,
    height: 1,
    hvr: false,
    slctd: false,
  },
  {
    id: 10,
    source: "images/GallShowcase/GallShowcase10.webp",
    width: 3,
    height: 2,
    hvr: false,
    slctd: false,
  },
  {
    id: 11,
    source: "images/GallShowcase/GallShowcase11.webp",
    width: 2,
    height: 1,
    hvr: false,
    slctd: false,
  },
  {
    id: 12,
    source: "images/GallShowcase/GallShowcase12.webp",
    width: 1,
    height: 2,
    hvr: false,
    slctd: false,
  },
  {
    id: 13,
    source: "images/GallShowcase/GallShowcase13.webp",
    width: 1,
    height: 2,
    hvr: false,
    slctd: false,
  },
  {
    id: 14,
    source: "images/GallShowcase/GallShowcase14.webp",
    width: 3,
    height: 2,
    hvr: false,
    slctd: false,
  },
  {
    id: 15,
    source: "images/GallShowcase/GallShowcase15.webp",
    width: 4,
    height: 3,
    hvr: false,
    slctd: false,
  },
  {
    id: 16,
    source: "images/GallShowcase/GallShowcase16.webp",
    width: 2,
    height: 1,
    hvr: false,
    slctd: false,
  },
  {
    id: 17,
    source: "images/GallShowcase/GallShowcase17.webp",
    width: 1,
    height: 1,
    hvr: false,
    slctd: false,
  },
  {
    id: 18,
    source: "images/GallShowcase/GallShowcase18.webp",
    width: 16,
    height: 9,
    hvr: false,
    slctd: false,
  },
  {
    id: 19,
    source: "images/GallShowcase/GallShowcase19.webp",
    width: 2,
    height: 3,
    hvr: false,
    slctd: false,
  },
  {
    id: 20,
    source: "images/GallShowcase/GallShowcase20.webp",
    width: 4,
    height: 3,
    hvr: false,
    slctd: false,
  },
];
const allpic2 = [
  {
    id: 1,
    source: "images/GallShowcase/GallShowcase21.webp",
    width: 1,
    height: 1,
    hvr: false,
    slctd: false,
  },
  {
    id: 2,
    source: "images/GallShowcase/GallShowcase22.webp",
    width: 1,
    height: 1,
    hvr: false,
    slctd: false,
  },
  {
    id: 3,
    source: "images/GallShowcase/GallShowcase23.webp",
    width: 3,
    height: 2,
    hvr: false,
    slctd: false,
  },
  {
    id: 4,
    source: "images/GallShowcase/GallShowcase24.webp",
    width: 10,
    height: 16,
    hvr: false,
    slctd: false,
  },
  {
    id: 5,
    source: "images/GallShowcase/GallShowcase25.webp",
    width: 4,
    height: 3,
    hvr: false,
    slctd: false,
  },
  {
    id: 6,
    source: "images/GallShowcase/GallShowcase26.webp",
    width: 1,
    height: 1,
    hvr: false,
    slctd: false,
  },
  {
    id: 7,
    source: "images/GallShowcase/GallShowcase27.webp",
    width: 2,
    height: 1,
    hvr: false,
    slctd: false,
  },
  {
    id: 8,
    source: "images/GallShowcase/GallShowcase28.webp",
    width: 16,
    height: 10,
    hvr: false,
    slctd: false,
  },
  {
    id: 9,
    source: "images/GallShowcase/GallShowcase29.webp",
    width: 1,
    height: 1,
    hvr: false,
    slctd: false,
  },
  {
    id: 10,
    source: "images/GallShowcase/GallShowcase30.webp",
    width: 3,
    height: 1,
    hvr: false,
    slctd: false,
  },
  {
    id: 11,
    source: "images/GallShowcase/GallShowcase31.webp",
    width: 4,
    height: 3,
    hvr: false,
    slctd: false,
  },
  {
    id: 12,
    source: "images/GallShowcase/GallShowcase32.webp",
    width: 3,
    height: 2,
    hvr: false,
    slctd: false,
  },
  {
    id: 13,
    source: "images/GallShowcase/GallShowcase33.webp",
    width: 16,
    height: 9,
    hvr: false,
    slctd: false,
  },
  {
    id: 14,
    source: "images/GallShowcase/GallShowcase34.webp",
    width: 3,
    height: 2,
    hvr: false,
    slctd: false,
  },
  {
    id: 15,
    source: "images/GallShowcase/GallShowcase35.webp",
    width: 1,
    height: 1,
    hvr: false,
    slctd: false,
  },
  {
    id: 16,
    source: "images/GallShowcase/GallShowcase36.webp",
    width: 2,
    height: 1,
    hvr: false,
    slctd: false,
  },
  {
    id: 17,
    source: "images/GallShowcase/GallShowcase37.webp",
    width: 4,
    height: 3,
    hvr: false,
    slctd: false,
  },
  {
    id: 18,
    source: "images/GallShowcase/GallShowcase38.webp",
    width: 1,
    height: 1,
    hvr: false,
    slctd: false,
  },
  {
    id: 19,
    source: "images/GallShowcase/GallShowcase39.webp",
    width: 16,
    height: 9,
    hvr: false,
    slctd: false,
  },
  {
    id: 20,
    source: "images/GallShowcase/GallShowcase40.webp",
    width: 1,
    height: 1,
    hvr: false,
    slctd: false,
  },
];
ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
