import React, { Component, useState } from "react";
// import FileSaver from "../FileSaver.min.js";
class Imageshow extends Component {
  state = {
    hoveredpic: "false",
  };
  styleDivbig = {
    display: "flex",
    flexWrap: "wrap",
    gridGap: "6px",
    marginLeft: "10%",
    marginRight: "10%",
  };
  styleDivsmall = {
    display: "flex",
    flexWrap: "wrap",
    gridGap: "2px",
  };
  handleSizeScreen() {
    switch (this.props.typeScreen.size) {
      case "small":
        return this.styleDivsmall;

      default:
        return this.styleDivbig;
    }
  }

  stylePic = {
    position: "absolute",
    top: 0,
    width: "100%",
    verticalAlign: "bottom",
  };
  style = {
    display: "flex",
    position: "relative",
  };
  styleBut = {
    position: "relative",
    top: "5px",
    right: "5px",
    position: "absolute",
    width: 32,
    height: 32,
    borderRadius: 16,
    border: "4px solid white",
  };
  classButOff = "btn  inactive";
  classButOn = "btn  btn-success inactive";

  butDiff(hvr, slctd, id) {
    switch (slctd) {
      case true:
        return (
          <button
            onClick={() => this.props.clickBut(slctd, id)}
            className={slctd ? this.classButOn : this.classButOff}
            style={this.styleBut}
            aria-pressed="false"
          ></button>
        );

      default:
        switch (hvr) {
          case true:
            return (
              <button
                onClick={() => this.props.clickBut(slctd, id)}
                className={slctd ? this.classButOn : this.classButOff}
                style={this.styleBut}
              ></button>
            );

          default:
            break;
        }
    }
  }
  render() {
    return (
      <div style={this.handleSizeScreen()}>
        {this.props.Allpic.map((pic) => (
          <div
            style={{
              ...this.style,
              width:
                (pic.width * 350) / pic.height / this.props.typeScreen.value,
              flexGrow:
                (pic.width * 350) / pic.height / this.props.typeScreen.value,
              maxWidth:
                (pic.width * 1000) / pic.height / this.props.typeScreen.value,
            }}
            onMouseEnter={() => this.props.showBut(pic.id)}
            onMouseLeave={() => this.props.hideBut(pic.id)}
          >
            <i
              style={{
                paddingBottom: (pic.height / pic.width) * 100 + "%",
              }}
            ></i>
            <a
              data-fancybox="gallery"
              href={pic.source}
              data-type="image"
              style={{ cursor: "zoom-in" }}
            >
              <img src={pic.sourcesmall} style={this.stylePic} />
            </a>

            {this.butDiff(pic.hvr, pic.slctd, pic.id)}
          </div>
        ))}
      </div>
    );
  }
}

export default Imageshow;
