import React, { Component } from "react";
import SlideCarousel from "./SlidesCarousel";
import {
  ButtonBack,
  ButtonFirst,
  ButtonLast,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
  Image,
  DotGroup,
} from "pure-react-carousel";
import BeforeAfterSlider from "react-before-after-slider";

import "pure-react-carousel/dist/react-carousel.es.css";

class BAcarousel extends Component {
  state = {
    pic1befaft: "../images/beforeafter/picBA01-1.webp",
    pic2befaft: "../images/beforeafter/picBA01-2.webp",
    Picsbefaf: [
      {
        id: 0,
        sourceOld: "../images/beforeafter/picBA01-1.webp",
        sourceNew: "../images/beforeafter/picBA01-2.webp",
      },
      {
        id: 1,
        sourceOld: "../images/beforeafter/picBA02-1.webp",
        sourceNew: "../images/beforeafter/picBA02-2.webp",
      },
      {
        id: 2,
        sourceOld: "../images/beforeafter/picBA03-1.webp",
        sourceNew: "../images/beforeafter/picBA03-2.webp",
      },
      {
        id: 3,
        sourceOld: "../images/beforeafter/picBA04-1.webp",
        sourceNew: "../images/beforeafter/picBA04-2.webp",
      },
      {
        id: 4,
        sourceOld: "../images/beforeafter/picBA05-1.webp",
        sourceNew: "../images/beforeafter/picBA05-2.webp",
      },
      {
        id: 5,
        sourceOld: "../images/beforeafter/picBA06-1.webp",
        sourceNew: "../images/beforeafter/picBA06-2.webp",
      },
      {
        id: 6,
        sourceOld: "../images/beforeafter/picBA07-1.webp",
        sourceNew: "../images/beforeafter/picBA07-2.webp",
      },
      {
        id: 7,
        sourceOld: "../images/beforeafter/picBA08-1.webp",
        sourceNew: "../images/beforeafter/picBA08-2.webp",
      },
      {
        id: 8,
        sourceOld: "../images/beforeafter/picBA09-1.webp",
        sourceNew: "../images/beforeafter/picBA09-2.webp",
      },
      {
        id: 9,
        sourceOld: "../images/beforeafter/picBA10-1.webp",
        sourceNew: "../images/beforeafter/picBA10-2.webp",
      },
    ],
    screenWidth: 1200,
    sizeBefaf: 1000,
    NumberSlides: 8,
  };

  handleResize = (e) => {
    let screenWidth = window.innerWidth;

    this.setState({ screenWidth });
    console.log(screenWidth);
    let sizeBefaf = screenWidth * 0.8;
    sizeBefaf > 1200 ? (sizeBefaf = 1200) : (sizeBefaf = sizeBefaf);
    this.setState({ sizeBefaf });
    console.log(sizeBefaf);

    let NumberSlides = Math.round(
      ((screenWidth / 200) * 1) / Math.sqrt(screenWidth / 600)
    );
    NumberSlides > 10 ? (NumberSlides = 10) : (NumberSlides = NumberSlides);
    this.setState({ NumberSlides });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.addEventListener("resize", this.handleResize);
  }

  selectPic = (val) => {
    let pic1befaft = this.state.pic1befaft;
    let pic2befaft = this.state.pic2befaft;

    console.log(val);
    pic1befaft = this.state.Picsbefaf[val].sourceOld;
    pic2befaft = this.state.Picsbefaf[val].sourceNew;
    this.setState({ pic1befaft });
    this.setState({ pic2befaft });
  };

  render() {
    return (
      <React.Fragment>
        <CarouselProvider
          visibleSlides={this.state.NumberSlides}
          totalSlides={10}
          step={1}
          naturalSlideWidth={300}
          naturalSlideHeight={200}
        >
          <Slider>
            {this.state.Picsbefaf.map((pic) => (
              <SlideCarousel
                selectPic={this.selectPic}
                ValSlide={pic.id}
                srcslide={pic.sourceNew}
              />
            ))}
          </Slider>
          {/* <ButtonFirst>First</ButtonFirst>
          <ButtonBack>Back</ButtonBack>
          <ButtonNext>Next</ButtonNext>
          <ButtonLast>Last</ButtonLast> */}
          {/* <DotGroup dotNumbers /> */}
        </CarouselProvider>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            margin: "5% 5% 5% 5%",
          }}
        >
          <h4>
            Move the slider on the picture to see it before and after rework!
          </h4>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "5%",
          }}
        >
          <BeforeAfterSlider
            before={this.state.pic1befaft}
            after={this.state.pic2befaft}
            width={this.state.sizeBefaf}
            height={(this.state.sizeBefaf * 2) / 3}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default BAcarousel;
