import React, { Component } from "react";
import simplegallery from "../images/AboutMe/Simplegallery.png";

const IntroMyPictures = () => {
  return (
    <div
      style={{
        margin: "25px",
      }}
    >
      <h1
        style={{
          textAlign: "center",
        }}
      >
        A Simple Gallery
      </h1>
      <img
        src={simplegallery}
        alt="Sample photo"
        style={{
          maxWidth: "600px",
          margin: "auto",
          display: "block",
          marginTop: "40px",
          marginBottom: "40px",
          width: "100%",
        }}
      />

      <h4>This is still in development!</h4>
      <p>
        I am currently working on this project, the goal is to create a gallery
        which is:
        <ul>
          <li>responsive</li>
          <li>dowloadable</li>
          <li>simple</li>
        </ul>
      </p>

      <p>
        I used different tools to create this page:
        <ul>
          <li>ReactJs for the main functions</li>
          <li>Bootstrap for the component styles</li>
          <li>Fancybox for the picture viewer</li>
        </ul>
      </p>
      <p>
        Next goals:
        <ul>
          <li>Downloadable content</li>
        </ul>
      </p>
    </div>
  );
};
export default IntroMyPictures;
