import React, { Component } from "react";
import Buttonmenu from "./button-menu1";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun } from "@fortawesome/free-solid-svg-icons";
import { faMoon } from "@fortawesome/free-solid-svg-icons";
import "../styles.css";

class Menu extends Component {
  state = {
    tree: [
      {
        id: 1,
        link: "/",
        ref: "Home",
      },
      {
        id: 2,
        link: "/gallery",
        ref: "Simple gallery",
      },
      {
        id: 3,
        link: "/beforeafter",
        ref: "Before-after",
      },
    ],
    ThemeButton: true,
  };
  setTheme(color) {
    if (color === "default") color = "#08e608";
    document.documentElement.style.setProperty("--main-color", color);
  }

  handleDarkmode = () => {
    let ThemeButton = this.state.ThemeButton;
    if (ThemeButton) {
      ThemeButton = false;
      document.documentElement.style.setProperty(
        "--darkergrey",
        "rgb(77, 77, 77)"
      );
      document.documentElement.style.setProperty(
        "--TextHover",
        "rgb(170, 170, 170)"
      );
      document.documentElement.style.setProperty(
        "--Text",
        "rgb(231, 231, 231)"
      );
      document.documentElement.style.setProperty(
        "--linkwithblue",
        "rgb(107, 141, 212)"
      );
      document.documentElement.style.setProperty("--colback", "#343a40");
      document.documentElement.style.setProperty("--progresslight", "#d6d6d6");
      document.documentElement.style.setProperty("--progressdark", "#a3a3a3");
      document.documentElement.style.setProperty("--shadows", "#343a40");
      document.documentElement.style.setProperty("--lines", "#525252");
      document.documentElement.style.setProperty("--lines1", "#525252");
    } else {
      ThemeButton = true;
      document.documentElement.style.setProperty("--darkergrey", "#525252");
      document.documentElement.style.setProperty(
        "--TextHover",
        "rgb(112, 112, 112)"
      );
      document.documentElement.style.setProperty("--Text", "#343a40");
      document.documentElement.style.setProperty(
        "--linkwithblue",
        "rgb(107, 141, 212)"
      );
      document.documentElement.style.setProperty("--colback", "#ffffff");
      document.documentElement.style.setProperty("--progresslight", "#cacaca");
      document.documentElement.style.setProperty("--progressdark", "#686868");
      document.documentElement.style.setProperty("--shadows", "#cacaca");
      document.documentElement.style.setProperty("--lines", "#343a40");
      document.documentElement.style.setProperty("--lines1", "#ffffff");
    }

    this.setState({ ThemeButton });
    const useDark = window.matchMedia("(prefers-color-scheme: dark)");
    console.log(ThemeButton);
    document.documentElement.classList.toggle("dark-mode");
  };

  render() {
    let cssProperties = {};
    cssProperties["--colback"] = "#525252";
    return (
      <React.Fragment>
        <nav
          class=" navbar navbar-dark bg-dark navbar-expand-lg   sticky-top"
          id="MenuNav"
        >
          <div class="container-fluid">
            <a class="navbar-brand" href="/">
              Romain Gille
            </a>
            <button
              type="button"
              class="navbar-toggler"
              data-toggle="collapse"
              data-target="#navbarTogglerDemo01"
              aria-controls="navbarTogglerDemo01"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>

            <div
              class="collapse navbar-collapse "
              id="navbarTogglerDemo01"

              // style={{
              //   position: "absolute",
              //   left: "50%",
              //   transform: "translatex(-50%)",
              // }}
            >
              <ul class="nav navbar-nav">
                {this.state.tree.map((buttonmenu) => (
                  <Buttonmenu
                    key={buttonmenu.id}
                    link={buttonmenu.link}
                    reference={buttonmenu.ref}
                  />
                ))}
              </ul>
              <div
                class="Container"
                style={{
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "right",
                  flexGrow: 1,
                }}
              >
                <BootstrapSwitchButton
                  type="checkbox"
                  data-toggle="switchbutton"
                  checked
                  onstyle="warning"
                  offstyle="dark"
                  style="border"
                  onlabel={<FontAwesomeIcon icon={faSun} size="lg" />}
                  offlabel={<FontAwesomeIcon icon={faMoon} size="lg" />}
                  onChange={() => {
                    this.handleDarkmode();
                  }}
                ></BootstrapSwitchButton>

                {/* <div
                  class="custom-control custom-switch"
                  style={{ height: "80px" }}
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    style={{ height: "80px" }}
                    id="customSwitches"
                  />
                  <label
                    class="custom-control-label"
                    style={{ height: "80px" }}
                    for="customSwitches"
                  ></label>
                </div> */}
              </div>
            </div>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}

export default Menu;
