import React, { Component } from "react";
// import "../styleaboutme.css";
// import "../DarkMode.css";
import calaboo from "../images/AboutMe/calaboo.webp";
import camera from "../images/AboutMe/camera.png";
import DE from "../images/AboutMe/DE.png";
import email from "../images/AboutMe/emaildark.webp";
import EN from "../images/AboutMe/EN.png";
import EPF from "../images/AboutMe/EPF.webp";
import EVUM from "../images/AboutMe/EVUM.webp";
import FR from "../images/AboutMe/FR.png";
import gawad from "../images/AboutMe/gawad.webp";
import guit from "../images/AboutMe/guit.png";
import HM from "../images/AboutMe/HM.webp";
import LFA from "../images/AboutMe/LFA.webp";
import Linkedin from "../images/AboutMe/Linkedin.webp";
import mountains from "../images/AboutMe/mountains.png";
import soundcloudg from "../images/AboutMe/soundcloud.gif";
import soundcloud from "../images/AboutMe/soundcloud.png";
import unsplashB from "../images/AboutMe/unsplashB.png";
import unsplashW from "../images/AboutMe/unsplashW.png";
import volvo from "../images/AboutMe/volvo.webp";
import rorobo from "../images/AboutMe/rorobo.webp";

class AboutMe extends Component {
  state = {
    shownpic: rorobo,
  };

  handleMouseOver = (val) => {
    let shownpic = rorobo;
    switch (val) {
      case 1:
        shownpic = EVUM;
        break;
      case 2:
        shownpic = calaboo;
        break;
      case 3:
        shownpic = gawad;
        break;
      case 4:
        shownpic = volvo;
        break;
      case 5:
        shownpic = HM;
        break;
      case 6:
        shownpic = HM;
        break;
      case 7:
        shownpic = EPF;
        break;
      case 8:
        shownpic = LFA;
        break;

      default:
        shownpic = rorobo;
        break;
    }
    this.setState({ shownpic });
    console.log(val);
  };
  handleMouseOut = (val) => {
    let shownpic = rorobo;
    this.setState({ shownpic });
  };

  render() {
    return (
      <div className="background">
        <title>Romain GILLE</title>
        <div className="surround textbasic">
          <div className="column">
            <div className="column left">
              <img
                className="CVim"
                src={this.state.shownpic}
                alt="Sample photo"
              />
            </div>
            <div className="column right">
              <div className="titre">Romain GILLE</div>
              <div className="soustitre">
                Engineer in automation and web development
              </div>
              {/* ABOUT ME */}
              <div className="section"> About me</div>
              <div className="about">
                I'm a motivated engineer interested in web development and
                process automation. I love to create simple and beautiful
                interfaces from scratch. In order to achieve it, I discovered
                many different programms like Microsoft Access, Powerapps, Adobe
                Lightroom and Inkscape. Actually Learning Front-End development,
                mastering ReactJs, Angular, Javascript, CSS and HTML motivates
                me. From various backgrounds, I now want to discover and tackle
                new challenges.
              </div>
              {/* WORK EXPERIENCE */}
              <div className="section"> Work experience</div>
              <div className="workexp">
                Process planer @
                <a
                  className="links refCV work0"
                  href="https://evum-motors.com/"
                  onMouseOver={() => this.handleMouseOver(1)}
                  onMouseOut={() => this.handleMouseOut(1)}
                >
                  EVUM Motors
                </a>
              </div>
              <div className="textlight date">July 2020 - Today</div>
              <ul className="infowork">
                <li>
                  Creation of a database and an interface for the production
                </li>
                <li>
                  Development of an application for the logistics department
                </li>
                <li>Automation of the data processes</li>
                <li>Support of the production</li>
              </ul>
              <div className="workexp">
                International volunteering mission @
                <a
                  className="links refCV work1"
                  href="http://www.calaboo.com/"
                  onMouseOver={() => this.handleMouseOver(2)}
                  onMouseOut={() => this.handleMouseOut(2)}
                >
                  Calaboo
                </a>
              </div>
              <div className="textlight date">January - March 2020</div>
              <ul className="infowork">
                <li>Creation and design of a milk transformation process</li>
                <li>Elaboration of specifications</li>
                <li>Benchmarking of the different solutions on the market</li>
                <li>Design of the facility </li>
              </ul>
              <div className="workexp">
                Development mission @
                <a
                  className="links refCV work2"
                  href="https://gk1world.com/home"
                  onMouseOver={() => this.handleMouseOver(3)}
                  onMouseOut={() => this.handleMouseOut(3)}
                >
                  Gawad Kalinga
                </a>
              </div>
              <div className="textlight date">September- december 2019</div>
              <ul className="infowork">
                <li>
                  Creation of a sustainable business with a developing community{" "}
                </li>
                <li>Conception of a jam manufacturing facility</li>
                <li>Coaching and training of small teams</li>
                <li>Market study </li>
              </ul>
              <div className="workexp">
                Master student @
                <a
                  className="links refCV work3"
                  href="https://www.volvoce.com/"
                  onMouseOver={() => this.handleMouseOver(4)}
                  onMouseOut={() => this.handleMouseOut(4)}
                >
                  Volvo Construction Equipment
                </a>
              </div>
              <div className="textlight date">January - July 2019</div>
              <ul className="infowork">
                <li>Creation of new layouts of the production line</li>
                <li>
                  Simulation of new processes on Tecnomatix Plant simulation{" "}
                </li>
                <li>Evaluation of the different concepts </li>
                <li>Competitive intelligence </li>
              </ul>
              <div className="workexp">
                Bachelor student @
                <a
                  className="links refCV work4"
                  href="https://www.hm.edu/en/index.en.html"
                  onMouseOver={() => this.handleMouseOver(5)}
                  onMouseOut={() => this.handleMouseOut(5)}
                >
                  University of Applied Sciences Munich{" "}
                </a>
              </div>
              <div className="textlight date">September - November 2017</div>
              <ul className="infowork">
                <li>
                  Development of a system for non-contact fingerprint detection
                </li>
                <li>Arduino Programming</li>
                <li>Elaboration of a prototype </li>
              </ul>
              {/* Education */}
              <div className="section"> Education</div>
              <div className="workexp">
                French-german master degree in production and automation @
                <a
                  className="links refCV work4"
                  href="https://www.hm.edu/en/index.en.html"
                  onMouseOver={() => this.handleMouseOver(6)}
                  onMouseOut={() => this.handleMouseOut(6)}
                >
                  University of Applied Sciences Munich{" "}
                </a>
                and @
                <a
                  className="links refCV work5"
                  href="https://www.epf.fr/"
                  onMouseOver={() => this.handleMouseOver(7)}
                  onMouseOut={() => this.handleMouseOut(7)}
                >
                  EPF Sceaux{" "}
                </a>
              </div>
              <div className="textlight date">July 2019</div>
              <ul className="infowork">
                <li>
                  Double degree course between a french and a german school
                </li>
                <li>Simulation and improvement</li>
                <li>Logistics </li>
                <li>Robot programming </li>
                <li>Signals and systems </li>
              </ul>
              <div className="workexp">
                French-german highschool diploma @
                <a
                  className="links refCV work6"
                  href="http://www.lfa-buc.fr/"
                  onMouseOver={() => this.handleMouseOver(8)}
                  onMouseOut={() => this.handleMouseOut(8)}
                >
                  LFA Buc
                </a>
              </div>
              <div className="textlight date">June 2014</div>
              <ul className="infowork">
                <li>
                  Scientific curriculum specialized in mathematics and physics
                </li>
              </ul>
              {/* Skills */}
              <div className="section"> Skills</div>
              <div className="bars">
                <div className="myskill">Siemens plant simulation</div>
                <div className="progress">
                  <div className="progressin prog1"> 100%</div>
                </div>

                <div className="myskill">
                  Microsoft Suite (Access, Excel, Powerpoint)
                </div>
                <div className="progress">
                  <div className="progressin prog3">100%</div>
                </div>

                <div className="myskill">Dassault 3Dexperience</div>
                <div className="progress">
                  <div className="progressin prog2">80%</div>
                </div>
                <div className="myskill">
                  Microsoft Powerapps and Power automate
                </div>
                <div className="progress">
                  <div className="progressin prog2">80%</div>
                </div>
                <div className="myskill">
                  Photography (portraiture, Photoshop, Lightroom)
                </div>
                <div className="progress">
                  <div className="progressin prog4">80%</div>
                </div>
                <div className="myskill">
                  Creative vector illustration (Inkscape)
                </div>
                <div className="progress">
                  <div className="progressin prog4">80%</div>
                </div>

                <div className="myskill">
                  Front end development(Reactjs, javascript, css, html, Angular)
                </div>
                <div className="progress">
                  <div className="progressin prog5">60%</div>
                </div>
              </div>
              {/* Languages */}
              <div className="section"> Languages</div>
              <div className="collang">
                <div className="col1">
                  <div className="littlelang">English</div>
                  <img className="langim" src={EN} alt="Sample photo" />
                  <div className="littlelang">Fluent</div>
                  <div className="textlight langsmaller">
                    Level C1 (TOEIC 990/990)
                  </div>
                </div>
                <div className="col2">
                  <div className="littlelang">German</div>
                  <img className="langim" src={DE} alt="Sample photo" />
                  <div className="littlelang">Fluent</div>
                  <div className="textlight langsmaller">
                    Level C1 (Goethe Institut)
                  </div>
                </div>
                <div className="col3">
                  <div className="littlelang">French</div>
                  <img className="langim" src={FR} alt="Sample photo" />
                  <div className="littlelang">Mother tongue</div>
                </div>
              </div>
              {/* Passions */}
              <div className="section"> Passions</div>
              <div className="collang">
                <div className="col1">
                  <div className="littlelang">Music</div>
                  <img
                    className="langim impassion"
                    src={guit}
                    alt="Sample photo"
                  />
                  <div className=" langsmaller">
                    Music, and mainly guitar and singing take an important part
                    of my free time. I particularly like to improvise and sing
                    with friends.
                    <br />
                    <br />
                    <a
                      className="links linktostuff"
                      href="https://soundcloud.com/user-533870676-962418520"
                    >
                      Visit my Soundcloud
                      {/* <img class="imtostuff soundcloud" src="images\CV/soundcloud.png" alt="Sample photo"> */}
                    </a>
                  </div>
                </div>
                <div className="col2">
                  <div className="littlelang">Photography</div>
                  <img
                    className="langim impassion"
                    src={camera}
                    alt="Sample photo"
                  />
                  <div className="langsmaller">
                    I like to discover the depths of photography with
                    portaitures and landscapes. I am doing a lot of image
                    editing too, which I find compelling.
                    <br />
                    <br />
                    <a
                      className="links linktostuff"
                      href="https://unsplash.com/@rgille"
                    >
                      Visit my Unsplash
                      {/* <img class="imtostuff unsplash" src="images\CV/unsplashW.png" alt="Sample photo"> */}
                    </a>
                  </div>
                </div>
                <div className="col3">
                  <div className="littlelang">Hiking</div>
                  <img
                    className="langim impassion"
                    src={mountains}
                    alt="Sample photo"
                  />
                  <div className=" langsmaller">
                    The freshness of the heights and the fantastics views given
                    by nature are two things that I cherish.{" "}
                  </div>
                </div>
              </div>
              <div className="contact">Contact me</div>
              <img className="contactim" src={email} alt="Sample photo" />
              <a className="links mail" href="mailto:gille.romain@yahoo.fr">
                gille.romain@yahoo.fr
              </a>
              <br />
              <br />
              <img className="contactim" src={Linkedin} alt="Sample photo" />
              <a
                className="links mail mail2"
                href="https://www.linkedin.com/in/romain-gille"
              >
                /in/romain-gille
              </a>
              <div className="textlight fleurs">
                Website created with ReactJs by Romain GILLE.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AboutMe;
