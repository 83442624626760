import React, { Component } from "react";
import reactDom from "react-dom";
import { Link } from "react-router-dom";
import intro from "./intro";

class Buttonmenu extends Component {
  state = {
    linktree: this.props.link,
    nametree: this.props.reference,
  };
  render() {
    return (
      <li className="nav-item" style={{ fontSize: "18px" }}>
        <Link to={this.state.linktree} className="nav-link big">
          {this.state.nametree}
        </Link>
      </li>
    );
  }
}

export default Buttonmenu;
