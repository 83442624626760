import React, { Component } from "react";

import {
  ButtonBack,
  ButtonFirst,
  ButtonLast,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
  Image,
  DotGroup,
} from "pure-react-carousel";

class SlideCarousel extends React.Component {
  render() {
    return (
      <Slide index={this.props.ValSlide}>
        <Image
          src={this.props.srcslide}
          onClick={() => this.props.selectPic(this.props.ValSlide)}
        />
      </Slide>
    );
  }
}

export default SlideCarousel;
