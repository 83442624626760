import React, { Component } from "react";
import Imageshow from "./picture";
import Navbar from "./navbar";

class PictureGall extends Component {
  contentView = false;
  numPeople = this.props.numPeople;
  Name = this.props.Name;
  DateTaken = this.props.DateTaken;
  location = this.props.location;
  state = {
    allpic: this.props.allpic,

    widthWin: { windowWidth: window.innerWidth },
    typeScreen: { size: "big", value: 1.2 },
  };
  handleResize = (e) => {
    let typeScreen = this.state.typeScreen;
    this.setState({ widthWin: window.innerWidth });
    this.state.widthWin < 800
      ? (typeScreen.value = 3)
      : (typeScreen.value = 1.2);
    this.state.widthWin < 800
      ? (typeScreen.size = "small")
      : (typeScreen.size = "big");
    this.setState({ typeScreen });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.addEventListener("resize", this.handleResize);
  }

  // Handle the apparition of the buttons when hovered, changes the hover state to true
  handleShowBut = (pic) => {
    let Allpic = this.state.allpic;
    console.log({ pic });
    Allpic[pic - 1].hvr = true;
    this.setState({ Allpic });
  };
  // Handle the apparition of the buttons, changes the selected state to true or false
  handleHideBut = (pic) => {
    let Allpic = this.state.allpic;
    console.log(this.state.allpic[pic - 1].slctd);
    if (this.state.allpic[pic - 1].slctd === false) {
      Allpic[pic - 1].hvr = false;
      this.setState({ Allpic });
    }
  };

  handleClickBut = (pic, id) => {
    let Allpic = this.state.allpic;

    if (Allpic[id - 1].slctd === false) {
      Allpic[id - 1].slctd = true;
      Allpic[id - 1].hvr = true;
    } else {
      Allpic[id - 1].slctd = false;
    }
    console.log({ Allpic });
    this.setState({ Allpic });
  };

  handleSelectAll = () => {
    let Allpic = this.state.allpic;

    Allpic.map((pic) => (pic.slctd = true));
    this.setState({ Allpic });
  };
  handleDeselectAll = () => {
    let Allpic = this.state.allpic;

    Allpic.map((pic) => ((pic.slctd = false), (pic.hvr = false)));
    this.setState({ Allpic });
  };
  handleShowHidePic = (e) => {
    e.preventDefault();
    let contentView = this.state.contentView;
    contentView === true ? (contentView = false) : (contentView = true);

    this.setState({ contentView });
    console.log(this.state.contentView);
  };
  handleShowHide() {
    switch (this.state.contentView) {
      case true:
        return (
          <Imageshow
            Allpic={this.state.allpic}
            showBut={this.handleShowBut}
            hideBut={this.handleHideBut}
            butDiff={this.handleButDiff}
            clickBut={this.handleClickBut}
            widthWin={this.state.widthWin}
            typeScreen={this.state.typeScreen}
          />
        );

      default:
        break;
    }
  }

  // Navigation bar
  render() {
    return (
      <React.Fragment>
        <Navbar
          allpic={this.state.allpic}
          contentView={this.state.contentView}
          Name={this.Name}
          numPeople={this.numPeople}
          DateTaken={this.DateTaken}
          location={this.location}
          typeScreen={this.state.typeScreen}
          selectAll={this.handleSelectAll}
          deselectAll={this.handleDeselectAll}
          ShowHidePic={this.handleShowHidePic}
          widthWin={this.state.widthWin}
        />
        {this.handleShowHide()}
      </React.Fragment>
    );
  }
}

export default PictureGall;
